<template>
  <form class="account" autocomplete="off" @submit.prevent="handlePayment">
    <h5 v-if="profile.billing.card">Saved card: <strong>{{ profile.billing.card }}</strong></h5>
    <div ref="cardElement" class="card-input account"></div>
    <div ref="cardError" role="alert"></div><br>
    <button v-if="!isLoading">Update Payment Details</button>
    <button v-else disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
  </form>
</template>

<script>
import { ref, onMounted } from 'vue'
import useFetch from '@/composables/useFetch'
import { stripe, Toast } from '@/composables/getGlobalVariables'

export default {
  props: ['profile'],
  setup({ profile }) {
    const { patchData, error, isLoading } = useFetch()
    const cardElement = ref(null)
    const cardError = ref(null)
    let card

    const handlePayment = async () => {
      if (!profile.details.line1 || !profile.details.city || !profile.details.country) {
        Toast.fire({
          title: 'Hey there...',
          text: 'Please fill out the Billing Details section first',
          icon: 'error'
        })
      } else {
        isLoading.value = true
        
        try {
          const res = await stripe.createPaymentMethod({
            type: 'card',
            card: card,
          })
          
          if (res.paymentMethod) {
            await patchData(`/users/${profile.id}/payment`, { pmId: res.paymentMethod.id })

            if (!error.value) {
              Toast.fire({
                title: 'Success!',
                text: 'Your payment details were updated successfully',
                icon: 'success'
              })
            } else {
              throw ({ message: error.value })
            }
          }
        } catch(err) {
          Toast.fire({
            title: 'Oops...',
            text: err.message,
            icon: 'error'
          })
        } finally {
          isLoading.value = false
        }
      }
    }

    onMounted(() => {
      card = stripe.elements().create('card')
      card.mount(cardElement.value)

      card.on('change', ({ error }) => {
        cardError.value.textContent = (error) ? error.message : ''
      })
    })

    return { cardElement, cardError, handlePayment, isLoading }
  }
}
</script>

<style>
  .card-input {
    width: 100%;
    min-height: 20px;
    margin: 15px auto 5px auto;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    text-align: left;
  }
  #card-errors {
    margin-top: 10px;
  }
</style>