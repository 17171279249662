import { Toast } from '../composables/getGlobalVariables'

const setLocale = async (invoice, customerId) => {
  const obj = { 
		invoice: invoice, 
		customer: customerId 
  }

  localStorage.setItem('invoiceInfo', JSON.stringify(obj))
}

const resetLocale = async () => {
  if (localStorage.getItem('invoiceInfo')) {
		localStorage.removeItem('invoiceInfo')
  }
}

const handleStripePayment = async (stripe, lastPayment, customerId, isLoading, isRetry) => {
  if (lastPayment === 'Updated') {	
		resetLocale()
	
		Toast.fire({
			title: 'Awesome!',
			text: 'Subscription plan updated successfully',
			icon: 'success'
		})

		isRetry.value = false
		isLoading.value = false
  } else {
		if (lastPayment.status === 'requires_action') {
			const result = await stripe.confirmCardPayment(lastPayment.client_secret, {
				payment_method: lastPayment.payment_method
			})

			try {
				if (result.error) {
					setLocale(lastPayment.invoice, customerId)

					Toast.fire({
						title: 'Uh-oh...',
						text: result.error.message,
						icon: 'error'
					})

					isRetry.value = lastPayment.invoice
				} else {
					if (result.paymentIntent.status === 'succeeded') {
						resetLocale()
						
						Toast.fire({
							title: 'Awesome!',
							text: 'Subscription plan updated successfully',
							icon: 'success'
						})

						isRetry.value = false
					}
				}
			} catch(error) {
				Toast.fire({
					title: 'Uh-oh...',
					text: error.message,
					icon: 'error'
				})

				isRetry.value = false
			} finally {
				isLoading.value = false
			}
		} else if (lastPayment.status === 'requires_payment_method') {
			setLocale(lastPayment.invoice, customerId)
				
			Toast.fire({
				title: 'Uh-oh...',
				text: lastPayment.last_payment_error.message,
				icon: 'error'
			})

			isRetry.value = lastPayment.invoice
			isLoading.value = false
		} else {
			resetLocale()

			Toast.fire({
				title: 'Awesome!',
				text: 'Subscription plan updated successfully',
				icon: 'success'
			})

			isRetry.value = false
			isLoading.value = false
		}
  }
}

export { setLocale, resetLocale, handleStripePayment }