<template>
  <form class="account" @submit.prevent="handleCoupon">
    <div class="coupon-input">
      <input class="account" type="text" v-model="profile.billing.coupon" placeholder="Coupon Code">
    </div>
    <button v-if="!isLoading">Save Coupon</button>
    <button v-else disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
  </form>
</template>

<script>
import useFetch from '@/composables/useFetch'
import { Toast } from '@/composables/getGlobalVariables'

export default {
  props: ['profile'],
  setup({ profile }) {
    const { patchData, error, isLoading } = useFetch()

    const handleCoupon = async () => {
      await patchData(`/users/${profile.id}/coupon`, { couponCode: profile.billing.coupon })

      if (!error.value) {
        Toast.fire({
          title: 'Success!',
          text: 'Your coupon code was saved successfully and can be used right away',
          icon: 'success'
        })
      } else {
        Toast.fire({
          title: 'Oops...',
          text: error.value,
          icon: 'error'
        })
      }
    }

    return { handleCoupon, isLoading }
  }
}
</script>

<style>
  .coupon-input {
    width: 100%;
    min-height: 20px;
    margin: 15px auto 20px auto;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    text-align: left;
  }
  .coupon-input input {
    padding: 0;
  }
</style>