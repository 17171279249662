<template>
  <div v-if="profile" class="page-header">
    <div>
      <h3>Account</h3>
      <h6 style="font-weight: normal;">{{ headerMssg }}</h6>
    </div>
    <button v-if="isLoading" disabled class="secondary"><img src="../assets/images/spinner.svg" alt="Loading..."></button>
    <button v-else class="secondary" @click="handleAccount">Change Login Details</button>
  </div>
  <div v-if="profile" class="container">
    <div class="inner-container">
      <div class="page-subtitle">Billing Details 🧑</div>
      <BillingForm :profile="profile" />
    </div>
    <div class="inner-container">
      <div class="page-subtitle">Coupon 🏷️</div>
      <CouponForm :profile="profile" />
    </div>
    <div class="inner-container">
      <div class="page-subtitle">Payment Details 💳</div>
      <CardForm :profile="profile" />
    </div>
    <div class="inner-container">
      <div class="page-subtitle">Subscription Plans 📅</div>
      <PlanForm :profile="profile" />
    </div>
    <div class="inner-container account last">
      <div class="page-subtitle">Invoice Zone 🧾</div>
      <button v-if="isLoading" disabled class="secondary"><img src="../assets/images/spinner.svg" alt="Loading..."></button>
      <button v-else class="secondary" @click="showInvoices = true">View Your Invoices</button>
    </div>
    <div class="inner-container account last" style="float: right;">
      <div class="page-subtitle">Danger Zone ⛔</div>
      <button v-if="isLoading" disabled class="danger"><img src="../assets/images/spinner.svg" alt="Loading..."></button>
      <button v-else class="danger" @click="handleDelete">Delete Account</button>
    </div>
  </div>
  <Loading v-else />
  <InvoiceModal v-if="showInvoices && profile" :userId="profile.id" :customerId="profile.billing.customerId" @modal-close="showInvoices = false" />
</template>

<script>
import { ref, computed } from 'vue'
import getUser from '../composables/getUser'
import getCollection from '../composables/getCollection'
import useAuth from '../composables/useAuth'
import useFetch from '../composables/useFetch'
import { Swal, Toast } from '../composables/getGlobalVariables'
import BillingForm from '../components/Account/BillingForm.vue'
import CouponForm from '../components/Account/CouponForm.vue'
import CardForm from '../components/Account/CardForm.vue'
import PlanForm from '../components/Account/PlanForm.vue'
import InvoiceModal from '../components/Account/InvoiceModal.vue'
import Loading from '../components/Main/Loading.vue'

export default {
  components: { BillingForm, CouponForm, CardForm, PlanForm, InvoiceModal, Loading },
  setup() {
    const { user } = getUser()
    const { profile } = getCollection()
    const { updateDetails, confirmPassword, error: authError } = useAuth()
    const { deleteData, error, isLoading } = useFetch()
    const showInvoices = ref(false)

    const headerMssg = computed(() => {
      if (profile.value) {
        const date = new Date(profile.value.billing.date * 1000).toLocaleDateString()
        const amount = (profile.value.billing.nextAmnt / 100).toFixed(2)
        let planName = profile.value.billing.plan.replace(/-/g, ' ').split(' ')

        for (var i = 0; i < planName.length; i++) {
          planName[i] = planName[i].charAt(0).toUpperCase() + planName[i].substring(1)
        }

        const plan = planName.join(' ')

        if (profile.value.billing.plan === 'free-plan') {
          return 'You\'re currently subscribed to our Free Plan!';
        } else if (profile.value.billing.plan === 'lifetime') {
          return 'You\'re subscried to our SaaS Mantra Lifetime Deal, and you have access to all features! 🤩';
        } else if (profile.value.billing.status === 'active') {
          return `You're currently subscribed to our ${plan} and your next payment of $${amount} will be attempted on ${date}.`;
        } else if (profile.value.billing.status === 'incomplete' || profile.value.billing.status === 'inactive' || profile.value.billing.status === 'past_due') {
          return `Your subscription to ${plan} is currently inactive, due to a failed payment ☹️. Your next scheduled payment of $${amount} will be attempted on ${date}.`;
        } else if (profile.value.billing.status === 'incomplete_expired' || profile.value.billing.status === 'canceled') {
          return `Your subscription to ${plan} is over ☹️. Please resubscribe to our Free or Pro plan below.`;
        }
      }
    })

    const handleDelete = async () => {
      const popup = await Swal.fire({
        title: 'Uh-oh...',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Delete Account`,
        html: 
        `<h4>Deleting your account is irreversible and you'll lose all of your data.</h4><br>
        <h4>Do you confirm?</h4></div>`
      })
      
      if (popup.isConfirmed) {
        const newPopup = await Swal.fire({
          title: 'Confirm Your Password 🔒',
          showCancelButton: true,
          confirmButtonText: `Delete Account`,
          html:
          `<form>
            <input type="password" required id="confirm-password" class="swal2-input" placeholder="Confirm Your Password">
          </form>`,
          showLoaderOnConfirm: true,
          didOpen: () => {
            document.getElementById('confirm-password').focus();
          },
          preConfirm: () => {
            return new Promise(resolve => {
              Swal.resetValidationMessage();
              resolve(document.getElementById('confirm-password').value);
            });
          }
        })

        if (newPopup.isConfirmed) {
          await confirmPassword(user.value, newPopup.value)

          if (!authError.value) {
            await deleteData(`/users/${user.value.uid}`)

            if (!error.value) {
              window.location.reload()
            } else {
              Toast.fire({
                title: 'Oops...',
                text: error.value,
                icon: 'error'
              })
            }
          } else {
            Toast.fire({
              title: 'Oops...',
              text: authError.value,
              icon: 'error'
            })
          }
        }
      }
    }

    const handleAccount = async () => {
      const popup = await Swal.fire({
        title: 'Change Your Login Details 🔑',
        showCancelButton: true,
        confirmButtonText: 'Save Changes',
        html:
          `<form>
            <input type="text" id="name" required class="swal2-input" placeholder="Your Full Name" value="${user.value.displayName}">
            <input type="email" id="email" required class="swal2-input" placeholder="Email Address" value="${user.value.email}">
            <input type="password" required id="new-password" class="swal2-input" placeholder="New Password">
            <input type="password" required id="confirm-password" class="swal2-input" placeholder="Confirm New Password">
          </form>`,
        showLoaderOnConfirm: true,
        didOpen: () => {
          document.getElementById('name').focus();
        },
        preConfirm: () => {
          return new Promise(resolve => {
            const name = document.getElementById('name');
            const email = document.getElementById('email');
            const newPass = document.getElementById('new-password');
            const confirmPass = document.getElementById('confirm-password');

            if (name.value == '' || email.value == '') {
              Swal.showValidationMessage('Name & Email fields cannot be empty');
              Swal.getCancelButton().disabled = false;
              Swal.getConfirmButton().disabled = false;
              Swal.hideLoading();
            } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value)) {
              Swal.showValidationMessage('Your new emaill address doesn\'t look right');
              Swal.getCancelButton().disabled = false;
              Swal.getConfirmButton().disabled = false;
              Swal.hideLoading();
            } else if (newPass.value != '' && (newPass.value !== confirmPass.value)) {
              Swal.showValidationMessage('Your passwords must match');
              Swal.getCancelButton().disabled = false;
              Swal.getConfirmButton().disabled = false;
              Swal.hideLoading();
            } else if (newPass.value != '' && newPass.value.length < 6) {
              Swal.showValidationMessage('Your password must be at least 6 characters long');
              Swal.getCancelButton().disabled = false;
              Swal.getConfirmButton().disabled = false;
              Swal.hideLoading();
            } else {
              Swal.resetValidationMessage();
              resolve([
                document.getElementById('name').value,
                document.getElementById('email').value,
                document.getElementById('new-password').value
              ]);
            }
          });
        }
      })

      if (popup.isConfirmed && popup.value) {
        const name = popup.value[0];
        const email = popup.value[1];
        const newPass = popup.value[2];

        const newPopup = await Swal.fire({
          title: 'Enter Your Old Password 🔒',
          showCancelButton: true,
          confirmButtonText: 'Confirm Changes',
          focusConfirm: false,
          html:
          `<form>
            <input type="password" required id="confirm-password" class="swal2-input" placeholder="Confirm Your Current Password">
          </form>`,
          showLoaderOnConfirm: true,
          didOpen: () => {
            document.getElementById('confirm-password').focus();
          },
          preConfirm: () => {
            return new Promise(resolve => {
              Swal.resetValidationMessage();
              resolve(document.getElementById('confirm-password').value);
            });
          }
        })

        if (newPopup.isConfirmed) {
          isLoading.value = true 

          await confirmPassword(user.value, newPopup.value)
        
          if (!authError.value) {
            await updateDetails(user.value, name, email, newPass)

            if (!authError.value) {
              Toast.fire({
                title: 'Success!',
                text: 'Your login details were updated successfully',
                icon: 'success'
              })
            } else {
              Toast.fire({
                title: 'Oops...',
                text: authError.value,
                icon: 'error'
              })
            }
          } else {
            Toast.fire({
              title: 'Oops...',
              text: authError.value,
              icon: 'error'
            })
          }

          isLoading.value = false
        }
      }
    }

    return { profile, headerMssg, showInvoices, handleDelete, handleAccount, isLoading }
  }
}
</script>

<style>
  .inner-container.account.last {
    display: inline-block;
    width: 49%;
    margin-bottom: 0;
  }
  form.account {
    max-width: 100%;
    padding: 0 10px 10px 10px;
  }
  input.account {
    margin: 0;
    padding: 10px 0 0 10px;
    border: none;
  }
  select.account {
    width: 100%;
    margin-top: 5px;
    border: none;
    background-color: #fff0f0;
  }
  .account-input {
    display: inline-block;
    width: 50%;
    height: 70px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    text-align: left;
  }
  .account-input.wide {
    width: 100%;
  }
  .account-input.narrow {
    width: 33.333%;
  }
  .account-input label {
    font-weight: bold;
  }
</style>