<template>
  <form class="account" @submit.prevent="handlePlan">
    <div class="plan-wrapper">
        <div class="plan-input">
          <h3>Free Plan</h3>
          <ul><li>🚀 <strong>100</strong> Ratings per month</li>
              <li>⚠️ <strong>2</strong> Created Widgets</li>
              <li>❌ <strong>No</strong> Element Screenshot</li>
              <li>❌ <strong>No</strong> Response Voting</li>
              <li>©️ <strong>With</strong> Emojise Branding</li>
              <li>📨 <strong>Normal</strong> Support</li>
          </ul>
          <div class="radios account">
            <label :class="{ selected: planName == 'free-plan' }">Free Plan<br>$0.00/mo<input type="radio" name="sub-plan" id="free-plan" :value="prices.free" @change="handleChange" v-model="planId"></label>
          </div>
        </div>
        <div class="plan-input">
          <h3>Pro Plan</h3>
          <ul>
            <li>🚀 <strong>Unlimited</strong> Ratings</li>
            <li>♾️ <strong>Unlimited</strong> Widgets</li>
            <li>📷 <strong>Allow</strong> Element Screenshot</li>
            <li>🗳️ <strong>Allow</strong> Response Voting</li>
            <li>©️ <strong>Remove</strong> Emojise Branding</li>
            <li>📨 <strong>Priority</strong> Support</li>
          </ul>
          <div class="radios account">
            <label :class="{ selected: planName == 'pro-plan-monthly' }">
              <span v-if="!profile.billing.coupon">Monthly<br>$19.90/mo</span>
              <span v-else>Monthly<br>$13.93/mo</span>
              <input type="radio" name="sub-plan" id="pro-plan-monthly" :value="prices.proMonth" @change="handleChange" v-model="planId">
            </label>
            <label :class="{ selected: planName == 'pro-plan-yearly' }">
              <span v-if="!profile.billing.coupon">Yearly (2 Months Free)<br>$199.00/yr</span>
              <span v-else>Yearly (2 Months Free)<br>$139.30/yr</span>
              <input type="radio" name="sub-plan" id="pro-plan-yearly" :value="prices.proYear" @change="handleChange" v-model="planId">
            </label>
            <div :class="{ selected: planName == 'lifetime' }" class="like-link disabled" title="The LTD is no longer available to purchase" tabindex="0">Lifetime Deal<br>SOLD OUT</div>
          </div>
        </div>
    </div>
    <button v-if="!isLoading" style="margin-top: 20px;">Update Subscription Details</button>
    <button v-else disabled style="margin-top: 20px;"><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
    <button v-if="!isLoading && isRetry" class="secondary" type="click" @click="handleRetry" style="margin: 20px 0 0 20px;">Retry Subscription Payment</button>
    <button v-if="isLoading && isRetry" class="secondary" type="click" style="margin: 20px 0 0 20px;" disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
  </form>
</template>

<script>
import { ref, toRefs } from 'vue'
import useFetch from '@/composables/useFetch'
import { stripe, Toast } from '@/composables/getGlobalVariables'
import { handleStripePayment } from '@/composables/useStripeHandler'

export default {
  props: ['profile'],
  setup(props) {
    const { getData, patchData, error, isLoading } = useFetch()
    const { profile } = toRefs(props)
    const planName = ref(profile.value.billing.plan)
    const planId = ref(null)
    const isRetry = ref(false)
    const prices = ref({
      free: process.env.VUE_APP_STRIPE_FREE,
      proMonth: process.env.VUE_APP_STRIPE_PRO_MONTH,
      proYear: process.env.VUE_APP_STRIPE_PRO_YEAR
    })

    let lsItem = localStorage.getItem('invoiceInfo')
    if (lsItem) {
      let lsObj = JSON.parse(lsItem)
      if ((lsObj.customer == profile.value.billing.customerId) && lsObj.invoice) {
        isRetry.value = lsObj.invoice
      }
    }

    const handleChange = (e) => {
      planName.value = e.target.id
    }

    const handlePlan = async () => {
      if (!profile.value.billing.card && !profile.value.billing.pmId) {
        Toast.fire({
          title: 'Not so fast...',
          text: 'Please save your Card Details above first',
          icon: 'error'
        })
      } else {
        if (planId.value) {
          const toUpdate = (profile.value.billing.status !== 'canceled' && profile.value.billing.status !== 'incomplete_expired')
            ? true : false

          const lastPaymentIntent = await patchData(`/users/${profile.value.id}/subscription`, {
            planId: planId.value,
            planName: planName.value,
            toUpdate: toUpdate
          })

          if (!error.value) {
            handleStripePayment(stripe, lastPaymentIntent, profile.value.billing.customerId, isLoading, isRetry)
            getData(
              `/users/${profile.value.id}/next-invoice`,
              `customerId=${profile.value.billing.customerId}&subcriptionId=${profile.value.billing.subscriptionId}`,  
              true
            )
          } else {
            Toast.fire({
              title: 'Oops...',
              text: error.value,
              icon: 'error'
            })
          }
        }
      }
    }

    const handleRetry = async () => {
      if (isRetry.value) {
        const lastPaymentIntent = await getData(
          `/users/${profile.value.id}/repayment`, 
          `invoiceId=${isRetry.value}`,  
          true
        )

        if (!error.value) {
          handleStripePayment(stripe, lastPaymentIntent, profile.value.billing.customerId, isLoading, isRetry)
          
          getData(
            `/users/${profile.value.id}/next-invoice`,
            `customerId=${profile.value.billing.customerId}&subcriptionId=${profile.value.billing.subscriptionId}`,  
            true  
          )
        } else {
          Toast.fire({
            title: 'Oops...',
            text: error.value,
            icon: 'error'
          })
        }
      } else {
        Toast.fire({
          title: 'Hmm... Something went wrong...',
          text: 'Please try again, or contact us if problem persists',
          icon: 'error',
          confirmButtonText: 'Try again'
        })
      }
    }

    return { planName, planId, isRetry, prices, handleChange, handlePlan, handleRetry, isLoading }
  }
}
</script>

<style>
  .plan-wrapper {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 0 50px;
  }
  .plan-input {
    display: inline-block;
    width: 45%;
    height: 360px;
    padding: 20px 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    text-align: center;
  }
  .plan-input h3 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .plan-input li {
    margin: 10px 0;
    list-style-type: none;
  }
  .radios.account {
    display: flex;
    width: 100%;
    margin: 10px auto;
    justify-content: center;
  }
  .plan-input label, .plan-input a, .plan-input .like-link {
    margin-top: 20px;
    cursor: pointer;
    padding: 5px;
    color: var(--main);
    background-color: #ffffff;
    border: 1px solid;
    border-radius: 7px;
    margin-right: 10px;
    font-weight: bold;
  }
  .plan-input .like-link {
    margin-right: 0;
  }
  .plan-input label span, .plan-input a {
    color: var(--main);
  }
  .plan-input input[type=radio], .radios input[type=checkbox] {
    position: absolute;
    width: 0;
    opacity: 0;
    pointer-events: none;
  }
  .plan-input .selected {
    color: #ffffff;
    background-color: var(--main);
    border-color: var(--main);
  }
  .plan-input .selected span {
    color: #fff;
  }
  .plan-input .like-link.disabled {
    cursor: not-allowed;
  }
</style>