<template>
  <div class="modal show">
    <div class="inner-modal">
      <div class="navbar">
        <span class="close" @click="handleClose">&times;</span>
      </div>
  		<table v-if="!isLoading && invoices">
  		  <thead>
  			<tr>
  			  <th>STATUS</th>
  			  <th>DESCRIPTION</th>
  			  <th>PERIOD</th>
  			  <th>AMOUNT</th>
  			  <th>PDF</th>
  		    </tr>
  		  </thead>
  		  <tbody>
  		  	<tr v-for="invoice in invoices" :key="invoice.number">
  		  	  <td>{{ invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1) }}</td>
  		  	  <td>{{ invoice.number }}</td>
  		  	  <td>{{ `${new Date(invoice.lines.data[0].period.start * 1000).toLocaleDateString()} - ${new Date(invoice.lines.data[0].period.end * 1000).toLocaleDateString()}` }}</td>
  		  	  <td>{{ `$${(invoice.total / 100).toFixed(2)}` }}</td>
  		  	  <td><a :href="invoice.invoice_pdf" target="_blank"><i class="fas fa-file-pdf"></i></a></td>
  		  	</tr>
  		  </tbody>
  		</table>
      <div v-else-if="!isLoading && !invoices" class="error">Something went wrong fetching your invoices</div>
      <div v-else-if="isLoading">⏳ Fetching invoices...</div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import useFetch from '@/composables/useFetch'

export default {
  props: ['userId', 'customerId'],
  emits: ['modal-close'],
  setup({ userId, customerId }, { emit }) {
    const { getData, isLoading, error } = useFetch()
    const invoices = ref(null)

    const handleInvoices = async () => {
      const invoiceList = await getData(
        `/users/${userId}/list-invoices`,
        null,
        true
      )

      invoices.value = (!error.value) ? invoiceList : null
    }

  	const handleClose = () => {
      emit('modal-close')
    }

    onMounted(() => {
      handleInvoices()
    })

    return { handleClose, invoices, isLoading, error }
  }
}
</script>

<style scoped>
  table {
    margin: auto;
  }
  table th, table td {
    padding: 5px 40px;
    text-align: center;
    background-color: #b9dbe6;
  }
</style>